import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import AbstractButton from '../../../base/toolbox/components/AbstractButton';
import { isSharingStatus } from '../../functions';
/**
 * Implements an {@link AbstractButton} to open the user documentation in a new window.
 */
class SharedVideoButton extends AbstractButton {
}
/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {IProps}
 */
function _mapStateToProps(state) {
    const { disabled: sharedVideoBtnDisabled, status: sharedVideoStatus } = state['features/shared-video'];
    return {
        _isDisabled: Boolean(sharedVideoBtnDisabled),
        _sharingVideo: isSharingStatus(sharedVideoStatus ?? '')
    };
}
export default translate(connect(_mapStateToProps)(SharedVideoButton));
