import { SETTINGS_UPDATED } from './actionTypes';
/**
 * Create an action for when the settings are updated.
 *
 * @param {Object} settings - The new (partial) settings properties.
 * @returns {{
 *     type: SETTINGS_UPDATED,
 *     settings: {
 *         audioOutputDeviceId: string,
 *         avatarURL: string,
 *         cameraDeviceId: string,
 *         displayName: string,
 *         email: string,
 *         localFlipX: boolean,
 *         micDeviceId: string,
 *         serverURL: string,
 *         soundsReactions: boolean,
 *         startAudioOnly: boolean,
 *         startWithAudioMuted: boolean,
 *         startWithVideoMuted: boolean,
 *         startWithReactionsMuted: boolean
 *     }
 * }}
 */
export function updateSettings(settings) {
    return {
        type: SETTINGS_UPDATED,
        settings
    };
}
// hansoo added
import { TOGGLE_VIDEO_RECORDING, TRIGGER_IMAGE_SNAPSHOT, TRIGGER_ANNOTATION_CLEAR, TOGGLE_ANNOTATION_DRAW_ERASE, UPDATE_UI_BUTTON_POSITION, LAST_HOVERED_BUTTON_INDEX, UPDATE_UI_HAND_MENU_POSITION, TRIGGER_FLIP_WINDOW_UI, TOGGLE_MOUSE_HAND_ANNOTATION } from './actionTypes';
/**
 * Action to toggle the video recording state.
 *
 * @returns {{
 *     type: TOGGLE_VIDEO_RECORDING
 * }}
 */
export const toggleMouseHandAnnotation = () => {
    console.log('hansoo Debug: <0> TOGGLE_MOUSE_HAND_ANNOTATION action dispatched');
    return {
        type: TOGGLE_MOUSE_HAND_ANNOTATION
    };
};
export const triggerFlipWindowUI = () => {
    console.log('hansoo Debug: <0> TRIGGER_FLIP_WINDOW_UI action dispatched');
    return {
        type: TRIGGER_FLIP_WINDOW_UI
    };
};
export const triggerAnnotationClear = () => {
    console.log('hansoo Debug: TRIGGER_ANNOTATION_CLEAR action dispatched');
    return {
        type: TRIGGER_ANNOTATION_CLEAR
    };
};
export const toggleAnnotationDrawErase = () => {
    console.log('hansoo Debug: TOGGLE_ANNOTATION_DRAW_ERASE action dispatched');
    return {
        type: TOGGLE_ANNOTATION_DRAW_ERASE
    };
};
export const toggleVideoRecording = (isRecording) => {
    console.log('hansoo Debug: toggleVideoRecording action dispatched');
    return {
        type: TOGGLE_VIDEO_RECORDING,
        payload: { isRecording }
    };
};
export const triggerImageSnapshot = () => {
    console.log('hansoo Debug: triggerImageSnapshot action dispatched');
    return {
        type: TRIGGER_IMAGE_SNAPSHOT
    };
};
// export const updateUIButtonPosition = (visible, x, y) => {
//     console.log("Dispatching UPDATE_UI_BUTTON_POSITION with payload:", { visible, x, y });
//     return {
//         type: UPDATE_UI_BUTTON_POSITION,
//         payload: { visible, x, y }
//         // payload: { visible, x: parseFloat(x), y: parseFloat(y) } // Ensure values are parsed as floats
//     };
// };
export const updateUIButtonPosition = (visible, x, y) => {
    // console.log("action.ts script -> UPDATE_UI_BUTTON_POSITION", { visible, x, y });
    return {
        type: UPDATE_UI_BUTTON_POSITION,
        payload: { visible, x, y }
    };
};
export const updateUIHandMenuPosition = (visible, x, y) => {
    // console.log("action.ts script -> updateUIHandMenuPosition", { visible, x, y });
    return {
        type: UPDATE_UI_HAND_MENU_POSITION,
        payload: { visible, x, y }
    };
};
export const updatelastHoveredButtonIndex = (button_index) => {
    // console.log("action.ts script -> updatelastHoveredButtonIndex", { button_index });
    return {
        type: LAST_HOVERED_BUTTON_INDEX,
        payload: button_index
    };
};
/*
export const triggerFlipHandUI = () => {
    // console.log("action.ts script -> updatelastHoveredButtonIndex", { button_index });
    return {
        type: FLIP_HAND_UI
    };
};*/
