import React from 'react';
import { useSelector } from 'react-redux';
const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    width: '100px',
    margin: '0px',
};
const ImageDisplay = ({ dataURL }) => {
    const isValidDataURL = (url) => {
        return !!url && url.startsWith('data:image/') && url.length > 22;
    };
    return (React.createElement("div", { style: styles }, isValidDataURL(dataURL) ? (React.createElement("img", { src: dataURL, alt: "Valid content", style: { width: '100%', height: '100%', objectFit: 'contain' } })) : (React.createElement("div", { style: {
            width: '100%',
            height: '100%',
            backgroundColor: 'red', // Red box placeholder
        } }))));
};
const FaceRecognitionDataProcessing = () => {
    const FaceRecognitionData = useSelector((state) => state['features/base/settings'].FaceRecognitionData);
    const faceFound = FaceRecognitionData?.faceFound;
    const match = FaceRecognitionData?.match;
    const text_notification = match
        ? 'Authorized ' + FaceRecognitionData?.label + ' ' + FaceRecognitionData?.text
        : 'Unauthorized';
    const text_color = match ? 'green' : 'red';
    if (faceFound) {
        const sampleimageUrl = FaceRecognitionData?.sampleimageUrl || null;
        const matchimageUrl = FaceRecognitionData?.matchimageUrl || null;
        return (React.createElement("div", null,
            React.createElement("div", { style: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 0 } },
                React.createElement(ImageDisplay, { dataURL: sampleimageUrl }),
                React.createElement(ImageDisplay, { dataURL: matchimageUrl })),
            React.createElement("div", { style: {
                    textAlign: 'center',
                    backgroundColor: 'white',
                    padding: '10px',
                    marginTop: '10px',
                    borderRadius: '5px', // Optional rounded corners
                } },
                React.createElement("div", { style: { fontSize: '14px', color: text_color } }, text_notification))));
    }
    else {
        return (React.createElement("div", { style: { textAlign: 'center' } },
            React.createElement("div", { style: {
                    fontSize: '14px',
                    color: 'blue',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '5px', // Optional rounded corners
                } }, "No Face Detected")));
    }
};
const FaceRecognitionUI = () => {
    return (React.createElement("div", { id: "Face Recognition", style: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            padding: '0px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: '0px',
            maxWidth: '300px',
            zIndex: 1000,
        } },
        React.createElement(FaceRecognitionDataProcessing, null)));
};
export default FaceRecognitionUI;
//   const text_notification = match
//     ? 'Authorized ' + 'sawhansoo' + ' ' + ' worker'
//     : 'Unauthorized'; // debug
//   const faceFound = true; // debug
//   const match = true; // debug
//     const sampleimageUrl: string | null = Face_Sample_Data_URL; // debug
//     const matchimageUrl: string | null = Face_Sample_Data_URL; // debug
