import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { isMobileBrowser } from '../../../base/environment/utils';
import { getLocalParticipant, isLocalParticipantModerator } from '../../../base/participants/functions';
import ContextMenu from '../../../base/ui/components/web/ContextMenu';
import { isReactionsButtonEnabled, shouldDisplayReactionsButtons } from '../../../reactions/functions.web';
import { isTranscribing } from '../../../transcribing/functions';
import { setHangupMenuVisible, setOverflowMenuVisible, setToolbarHovered, setToolboxVisible } from '../../actions.web';
import { getJwtDisabledButtons, getVisibleButtons, isButtonEnabled, isToolboxVisible } from '../../functions.web';
import { useKeyboardShortcuts, useToolboxButtons } from '../../hooks.web';
import HangupButton from '../HangupButton';
import { EndConferenceButton } from './EndConferenceButton';
import HangupMenuButton from './HangupMenuButton';
import { LeaveConferenceButton } from './LeaveConferenceButton';
import OverflowMenuButton from './OverflowMenuButton';
import Separator from './Separator';
import { toggleVideoRecording, triggerImageSnapshot, toggleAnnotationDrawErase, triggerAnnotationClear, toggleMouseHandAnnotation, triggerFlipWindowUI } from '../../../base/settings/actions';
const useStyles = makeStyles()(() => {
    return {
        contextMenu: {
            position: 'relative',
            right: 'auto',
            margin: 0,
            marginBottom: '8px',
            maxHeight: 'calc(100dvh - 100px)',
            minWidth: '240px'
        },
        hangupMenu: {
            position: 'relative',
            right: 'auto',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            margin: 0,
            padding: '16px',
            marginBottom: '4px'
        }
    };
});
/**
 * A component that renders the main toolbar.
 *
 * @param {IProps} props - The props of the component.
 * @returns {ReactElement}
 */
export default function Toolbox({ toolbarButtons }) {
    const { classes, cx } = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _toolboxRef = useRef(null);
    const conference = useSelector((state) => state['features/base/conference'].conference);
    const isNarrowLayout = useSelector((state) => state['features/base/responsive-ui'].isNarrowLayout);
    const clientWidth = useSelector((state) => state['features/base/responsive-ui'].clientWidth);
    const isModerator = useSelector(isLocalParticipantModerator);
    const customToolbarButtons = useSelector((state) => state['features/base/config'].customToolbarButtons);
    const iAmRecorder = useSelector((state) => state['features/base/config'].iAmRecorder);
    const iAmSipGateway = useSelector((state) => state['features/base/config'].iAmSipGateway);
    const overflowDrawer = useSelector((state) => state['features/toolbox'].overflowDrawer);
    const shiftUp = useSelector((state) => state['features/toolbox'].shiftUp);
    const overflowMenuVisible = useSelector((state) => state['features/toolbox'].overflowMenuVisible);
    const hangupMenuVisible = useSelector((state) => state['features/toolbox'].hangupMenuVisible);
    const buttonsWithNotifyClick = useSelector((state) => state['features/toolbox'].buttonsWithNotifyClick);
    const reduxToolbarButtons = useSelector((state) => state['features/toolbox'].toolbarButtons);
    const toolbarButtonsToUse = toolbarButtons || reduxToolbarButtons;
    const chatOpen = useSelector((state) => state['features/chat'].isOpen);
    const isDialogVisible = useSelector((state) => Boolean(state['features/base/dialog'].component));
    const jwt = useSelector((state) => state['features/base/jwt'].jwt);
    const localParticipant = useSelector(getLocalParticipant);
    const transcribing = useSelector(isTranscribing);
    // Do not convert to selector, it returns new array and will cause re-rendering of toolbox on every action.
    const jwtDisabledButtons = getJwtDisabledButtons(transcribing, isModerator, jwt, localParticipant?.features);
    const reactionsButtonEnabled = useSelector(isReactionsButtonEnabled);
    const _shouldDisplayReactionsButtons = useSelector(shouldDisplayReactionsButtons);
    const toolbarVisible = useSelector(isToolboxVisible);
    const mainToolbarButtonsThresholds = useSelector((state) => state['features/toolbox'].mainToolbarButtonsThresholds);
    const allButtons = useToolboxButtons(customToolbarButtons);
    // hansoo add start
    const isRecording = useSelector((state) => state['features/base/settings'].isRecording);
    const isAnnotationDrawErase = useSelector((state) => state['features/base/settings'].isAnnotationDrawErase);
    const uiButtonPosition = useSelector((state) => state['features/base/settings'].uiButtonPosition);
    const lastHoveredButtonIndex = useSelector((state) => state['features/base/settings'].lastHoveredButtonIndex || 0);
    const uiHandMenuPosition = useSelector((state) => state['features/base/settings'].uiHandMenuPosition);
    const uiMouseOrHand = useSelector((state) => state['features/base/settings'].uiMouseOrHand);
    const isNotBackCameraFlip = useSelector((state) => state['features/base/settings'].isNotBackCameraFlip);
    const isSendFlip = useSelector((state) => state['features/base/settings'].isSendFlip);
    // hansoo add end
    useKeyboardShortcuts(toolbarButtonsToUse);
    useEffect(() => {
        if (!toolbarVisible) {
            if (document.activeElement instanceof HTMLElement
                && _toolboxRef.current?.contains(document.activeElement)) {
                document.activeElement.blur();
            }
        }
    }, [toolbarVisible]);
    /**
     * Sets the visibility of the hangup menu.
     *
     * @param {boolean} visible - Whether or not the hangup menu should be
     * displayed.
     * @private
     * @returns {void}
     */
    const onSetHangupVisible = useCallback((visible) => {
        dispatch(setHangupMenuVisible(visible));
        dispatch(setToolbarHovered(visible));
    }, [dispatch]);
    /**
     * Sets the visibility of the overflow menu.
     *
     * @param {boolean} visible - Whether or not the overflow menu should be
     * displayed.
     * @private
     * @returns {void}
     */
    const onSetOverflowVisible = useCallback((visible) => {
        dispatch(setOverflowMenuVisible(visible));
        dispatch(setToolbarHovered(visible));
    }, [dispatch]);
    useEffect(() => {
        if (hangupMenuVisible && !toolbarVisible) {
            onSetHangupVisible(false);
            dispatch(setToolbarHovered(false));
        }
    }, [dispatch, hangupMenuVisible, toolbarVisible, onSetHangupVisible]);
    useEffect(() => {
        if (overflowMenuVisible && isDialogVisible) {
            onSetOverflowVisible(false);
            dispatch(setToolbarHovered(false));
        }
    }, [dispatch, overflowMenuVisible, isDialogVisible, onSetOverflowVisible]);
    /**
     * Key handler for overflow/hangup menus.
     *
     * @param {KeyboardEvent} e - Esc key click to close the popup.
     * @returns {void}
     */
    const onEscKey = useCallback((e) => {
        if (e?.key === 'Escape') {
            e?.stopPropagation();
            hangupMenuVisible && dispatch(setHangupMenuVisible(false));
            overflowMenuVisible && dispatch(setOverflowMenuVisible(false));
        }
    }, [dispatch, hangupMenuVisible, overflowMenuVisible]);
    /**
     * Dispatches an action signaling the toolbar is not being hovered.
     *
     * @private
     * @returns {void}
     */
    const onMouseOut = useCallback(() => {
        !overflowMenuVisible && dispatch(setToolbarHovered(false));
    }, [dispatch, overflowMenuVisible]);
    /**
     * Dispatches an action signaling the toolbar is being hovered.
     *
     * @private
     * @returns {void}
     */
    const onMouseOver = useCallback(() => {
        dispatch(setToolbarHovered(true));
    }, [dispatch]);
    /**
     * Handle focus on the toolbar.
     *
     * @returns {void}
     */
    const handleFocus = useCallback(() => {
        dispatch(setToolboxVisible(true));
    }, [dispatch]);
    /**
     * Handle blur the toolbar..
     *
     * @returns {void}
     */
    const handleBlur = useCallback(() => {
        dispatch(setToolboxVisible(false));
    }, [dispatch]);
    if (iAmRecorder || iAmSipGateway) {
        return null;
    }
    const endConferenceSupported = Boolean(conference?.isEndConferenceSupported() && isModerator);
    const isMobile = isMobileBrowser();
    const rootClassNames = `new-toolbox ${toolbarVisible ? 'visible' : ''} ${toolbarButtonsToUse.length ? '' : 'no-buttons'} ${chatOpen ? 'shift-right' : ''}`;
    const toolbarAccLabel = 'toolbar.accessibilityLabel.moreActionsMenu';
    const containerClassName = `toolbox-content${isMobile || isNarrowLayout ? ' toolbox-content-mobile' : ''}`;
    const { mainMenuButtons, overflowMenuButtons } = getVisibleButtons({
        allButtons,
        buttonsWithNotifyClick,
        toolbarButtons: toolbarButtonsToUse,
        clientWidth,
        jwtDisabledButtons,
        mainToolbarButtonsThresholds
    });
    // hansoo add start
    const customButton = [
        {
            key: 'drawerase',
            icon: isAnnotationDrawErase ? '🧽' : '✏️',
            label: '',
            onClick: () => {
                console.log('Draw/Erase button clicked!');
                dispatch(toggleAnnotationDrawErase());
            }
        },
        {
            key: 'clear',
            icon: '🧹',
            label: '',
            onClick: () => {
                console.log('Clear button clicked!');
                dispatch(triggerAnnotationClear());
            }
        },
        {
            key: 'image-snapshot',
            icon: '📸',
            label: '',
            onClick: () => {
                console.log('Snapshot button clicked!');
                dispatch(triggerImageSnapshot());
            }
        },
        {
            key: 'video-button',
            icon: isRecording ? '🔴' : '📹',
            label: '',
            onClick: () => {
                dispatch(toggleVideoRecording(false)); // always send false at first
                console.log('Video button clicked!');
                const event = new CustomEvent('videoRecordingToggled', {
                // detail: leftHandData
                });
                window.dispatchEvent(event); // send call to toggle, if system is successful, video recording becomes turned on
            }
        },
        {
            key: 'mouseHandControl',
            icon: uiMouseOrHand ? '🖱️' : '✋',
            label: '',
            onClick: () => {
                //                 console.log('Mouse control clicked');
                dispatch(toggleMouseHandAnnotation());
            },
        },
        {
            key: 'flipsend',
            //             icon: '🔄', // Flip window horizontally
            icon: isSendFlip ? '🔄' : '🔄',
            label: '',
            onClick: () => {
                //                 console.log('han sooFlip window horizontally clicked');
                dispatch(triggerFlipWindowUI());
            },
        },
    ];
    const ButtonRenderer = ({ button }) => (React.createElement("button", { onClick: () => {
            //                 console.log(`${button.key} clicked`);
            button.onClick();
        }, style: {
            backgroundColor: 'transparent',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50px',
            height: '50px', // Adjust as needed
        } },
        React.createElement("span", { style: { fontSize: '20px', display: 'inline-flex' } }, button.icon),
        React.createElement("span", null, button.label)));
    const updatedMainMenuButtons = [
        //         ...customButton, // Your custom button
        ...customButton.map(button => ({
            key: button.key,
            Content: () => React.createElement(ButtonRenderer, { button: button })
        })),
        ...mainMenuButtons, // Existing buttons
    ];
    // hansoo add end
    const raiseHandInOverflowMenu = overflowMenuButtons.some(({ key }) => key === 'raisehand');
    const showReactionsInOverflowMenu = _shouldDisplayReactionsButtons
        && ((!reactionsButtonEnabled && (raiseHandInOverflowMenu || isNarrowLayout || isMobile))
            || overflowMenuButtons.some(({ key }) => key === 'reactions'));
    const showRaiseHandInReactionsMenu = showReactionsInOverflowMenu && raiseHandInOverflowMenu;
    return (React.createElement("div", { className: cx(rootClassNames, shiftUp && 'shift-up'), id: 'new-toolbox' },
        React.createElement("div", { className: containerClassName },
            React.createElement("div", { className: 'toolbox-content-wrapper', onBlur: handleBlur, onFocus: handleFocus, ...(isMobile ? {} : {
                    onMouseOut,
                    onMouseOver
                }) },
                React.createElement("div", { className: 'toolbox-content-items', ref: _toolboxRef },
                    mainMenuButtons.map(({ Content, key, ...rest }) => Content !== Separator && (React.createElement(Content, { ...rest, buttonKey: key, key: key }))),
                    customButton.map(button => (React.createElement("button", { key: button.key, onClick: button.onClick, style: {
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '50px',
                            height: '50px',
                            border: 'none'
                        } },
                        React.createElement("span", { style: { fontSize: '20px' } }, button.icon),
                        React.createElement("span", { style: { marginLeft: '8px' } }, button.label)))),
                    Boolean(overflowMenuButtons.length) && (React.createElement(OverflowMenuButton, { ariaControls: 'overflow-menu', buttons: overflowMenuButtons.reduce((acc, val) => {
                            if (val.key === 'reactions' && showReactionsInOverflowMenu) {
                                return acc;
                            }
                            if (val.key === 'raisehand' && showRaiseHandInReactionsMenu) {
                                return acc;
                            }
                            if (acc.length) {
                                const prev = acc[acc.length - 1];
                                const group = prev[prev.length - 1].group;
                                if (group === val.group) {
                                    prev.push(val);
                                }
                                else {
                                    acc.push([val]);
                                }
                            }
                            else {
                                acc.push([val]);
                            }
                            return acc;
                        }, []), isOpen: overflowMenuVisible, key: 'overflow-menu', onToolboxEscKey: onEscKey, onVisibilityChange: onSetOverflowVisible, showRaiseHandInReactionsMenu: showRaiseHandInReactionsMenu, showReactionsMenu: showReactionsInOverflowMenu })),
                    isButtonEnabled('hangup', toolbarButtonsToUse) && (endConferenceSupported
                        ? React.createElement(HangupMenuButton, { ariaControls: 'hangup-menu', isOpen: hangupMenuVisible, key: 'hangup-menu', notifyMode: buttonsWithNotifyClick?.get('hangup-menu'), onVisibilityChange: onSetHangupVisible },
                            React.createElement(ContextMenu, { accessibilityLabel: t(toolbarAccLabel), className: classes.hangupMenu, hidden: false, inDrawer: overflowDrawer, onKeyDown: onEscKey },
                                React.createElement(EndConferenceButton, { buttonKey: 'end-meeting', notifyMode: buttonsWithNotifyClick?.get('end-meeting') }),
                                React.createElement(LeaveConferenceButton, { buttonKey: 'hangup', notifyMode: buttonsWithNotifyClick?.get('hangup') })))
                        : React.createElement(HangupButton, { buttonKey: 'hangup', customClass: 'hangup-button', key: 'hangup-button', notifyMode: buttonsWithNotifyClick.get('hangup'), visible: isButtonEnabled('hangup', toolbarButtonsToUse) })))))));
}
