// dynamicScriptLoader.js

// Function to dynamically load scripts
function loadScripts(scripts) {
  scripts.forEach((src) => {
    const script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript"; // Ensure it's set for normal JS files
    script.async = false; // Ensure scripts are executed in order
    script.onload = () => console.log(`${src} face recognition loaded successfully`);
    script.onerror = () => console.error(`face recognition Failed to load script: ${src}`);
    document.head.appendChild(script);
  });
}

// List of scripts to load
const scriptsToLoad = [
  // "https://video-conferencing-cache.s3.ap-southeast-1.amazonaws.com/face-api.js", // "face_recognition/face-api.js", // Corrected relative path
  // "https://code.jquery.com/jquery-3.6.0.min.js", // Remote jQuery
  // "https://video-conferencing-cache.s3.ap-southeast-1.amazonaws.com/faceRecognition.js" // "face_recognition/faceRecognition.js" // Corrected relative path


  // "face_recognition/face-api.js", // Corrected relative path
  // "https://code.jquery.com/jquery-3.6.0.min.js", // Remote jQuery
  // "face_recognition/faceRecognition.js" // Corrected relative path


  // "https://video-conferencing-cache.s3.ap-southeast-1.amazonaws.com/mace-api.js", // "face_recognition/face-api.js", // Corrected relative path
  // "https://code.jquery.com/jquery-3.6.0.min.js", // Remote jQuery
  // "https://video-conferencing-cache.s3.ap-southeast-1.amazonaws.com/maceRecognition.js" // "face_recognition/faceRecognition.js" // Corrected relative path
  //


  // "https://video-conferencing-cache.s3.ap-southeast-1.amazonaws.com/mace-api", // "face_recognition/face-api.js", // Corrected relative path
  // "https://code.jquery.com/jquery-3.6.0.min.js", // Remote jQuery
  // "https://video-conferencing-cache.s3.ap-southeast-1.amazonaws.com/maceRecognition" // "face_recognition/faceRecognition.js" // Corrected relative path


  "https://precious-creponne-2c3457.netlify.app/face-api.js",
  "https://precious-creponne-2c3457.netlify.app/faceDetectionControls.js",
  "https://precious-creponne-2c3457.netlify.app/faceRecognition.js",
  "https://code.jquery.com/jquery-3.6.0.min.js",
];

// console.log('face recognition dynamically loading start');
// // Dynamically load the scripts
// loadScripts(scriptsToLoad);
// console.log('face recognition dynamically loading end');

export function Startup_Face_Recognition()
{
  console.log('face recognition dynamically loading start');
  loadScripts(scriptsToLoad);
  console.log('face recognition dynamically loading end');
}
