// import React from 'react';
//
// import GlobalStyles from '../../base/ui/components/GlobalStyles.web';
// import JitsiThemeProvider from '../../base/ui/components/JitsiThemeProvider.web';
// import DialogContainer from '../../base/ui/components/web/DialogContainer';
// import ChromeExtensionBanner from '../../chrome-extension-banner/components/ChromeExtensionBanner.web';
// import OverlayContainer from '../../overlay/components/web/OverlayContainer';
//
// import { AbstractApp } from './AbstractApp';
//
// // Register middlewares and reducers.
// import '../middlewares';
// import '../reducers';
//
// // hansoo added
// // Import the Toolbox component
// //import Toolbox from '../../toolbox/components/web/Toolbox';
// import Toolbox from '../../toolbox/components/web/HandToolbox';
// import { combineReducers } from 'redux';
//
// // Redux Provider (inherited from parent)
// import { Provider } from 'react-redux';
//
// /**
//  * Root app {@code Component} on Web/React.
//  *
//  * @augments AbstractApp
//  */
// export class App extends AbstractApp {
//     /**
//      * Creates an extra {@link ReactElement}s to be added (unconditionally)
//      * alongside the main element.
//      *
//      * @abstract
//      * @protected
//      * @returns {ReactElement}
//      */
//     _createExtraElement() {
//         return (
//             <JitsiThemeProvider>
//                 <OverlayContainer />
//             </JitsiThemeProvider>
//         );
//     }
//
//     /**
//      * Overrides the parent method to inject {@link AtlasKitThemeProvider} as
//      * the topmost component.
//      *
//      * @override
//      */
//     _createMainElement(component: React.ComponentType, props?: Object) {
//         return (
//             <JitsiThemeProvider>
//                 <GlobalStyles />
//                 <ChromeExtensionBanner />
//                 {super._createMainElement(component, props)}
//             </JitsiThemeProvider>
//         );
//     }
//
//     /**
//      * Renders the platform-specific dialog container.
//      *
//      * @returns {React$Element}
//      */
//     _renderDialogContainer() {
//         return (
//             <JitsiThemeProvider>
//                 <DialogContainer />
//             </JitsiThemeProvider>
//         );
//     }
//
//     render() {
//
//         const { store } = this.state; // Access Redux store from state
//
//         // Ensure the store is available before rendering
//         if (!store) {
//             return null; // or a loading spinner or a fallback UI
//         }
//
//         return (
//             <Provider store={store}>
//                 <JitsiThemeProvider>
//                     <GlobalStyles />
//                     <ChromeExtensionBanner />
//
//                     {/* Main App UI */}
//                     <div style={{ display: 'flex', height: '100vh' }}>
//                         {/* Toolbox as a vertical sidebar */}
//                         <div style={verticalToolboxStyle}>
//                             <Toolbox />
//                         </div>
//
//                         {/* Main content of the app */}
//                         <div style={{ flex: 1 }}>
//                             {super._createMainElement(this.state.route.component, this.state.route.props)}
//                         </div>
//                     </div>
//
//                     {this._renderDialogContainer()}
//                 </JitsiThemeProvider>
//             </Provider>
//         );
//     }
// }
//
//
// // Styles for the vertical toolbox
// const verticalToolboxStyle = {
//     width: '0px', // Adjust the width of the toolbox
//     backgroundColor: '#333', // Dark background
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     padding: '0px',
//     overflowY: 'auto',
// };
import React from 'react';
import GlobalStyles from '../../base/ui/components/GlobalStyles.web';
import JitsiThemeProvider from '../../base/ui/components/JitsiThemeProvider.web';
import DialogContainer from '../../base/ui/components/web/DialogContainer';
import ChromeExtensionBanner from '../../chrome-extension-banner/components/ChromeExtensionBanner.web';
import OverlayContainer from '../../overlay/components/web/OverlayContainer';
import { AbstractApp } from './AbstractApp';
// Register middlewares and reducers.
import '../middlewares';
import '../reducers';
import Toolbox from '../../toolbox/components/web/HandToolbox';
//
// Import the FaceRecognitionUI component
import FaceRecognitionUI from '../../toolbox/components/web/FaceRecognitionUI'; // Update the path as per your project structure
import { Provider } from 'react-redux';
export class App extends AbstractApp {
    _createExtraElement() {
        return (React.createElement(JitsiThemeProvider, null,
            React.createElement(OverlayContainer, null)));
    }
    _createMainElement(component, props) {
        return (React.createElement(JitsiThemeProvider, null,
            React.createElement(GlobalStyles, null),
            React.createElement(ChromeExtensionBanner, null),
            super._createMainElement(component, props)));
    }
    _renderDialogContainer() {
        return (React.createElement(JitsiThemeProvider, null,
            React.createElement(DialogContainer, null)));
    }
    render() {
        const { store } = this.state;
        //         const store = this.state.store;
        if (!store) {
            return null;
        }
        //         return (
        //              <FaceRecognitionUI />
        //             );
        return (
        //             <Provider store={store}>
        React.createElement(Provider, { store: store },
            React.createElement(JitsiThemeProvider, null,
                React.createElement(GlobalStyles, null),
                React.createElement(ChromeExtensionBanner, null),
                React.createElement("div", { style: { display: 'flex', height: '100vh' } },
                    React.createElement("div", { style: verticalToolboxStyle },
                        React.createElement(Toolbox, null)),
                    React.createElement("div", { style: { flex: 1 } },
                        super._createMainElement(this.state.route.component, this.state.route.props),
                        React.createElement("div", { style: {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 1000,
                                pointerEvents: 'none' // Allow clicks to pass through
                            } },
                            React.createElement("div", { style: {
                                    pointerEvents: 'auto',
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px'
                                } },
                                React.createElement(FaceRecognitionUI, null))))),
                this._renderDialogContainer())));
    }
}
const verticalToolboxStyle = {
    width: '0px',
    backgroundColor: '#333',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px',
    overflowY: 'auto',
};
// import React from 'react';
// import { Provider } from 'react-redux';
// import store from '../redux/store'; // Ensure your Redux store is correctly exported
// import GlobalStyles from '../../base/ui/components/GlobalStyles.web';
// import JitsiThemeProvider from '../../base/ui/components/JitsiThemeProvider.web';
// import DialogContainer from '../../base/ui/components/web/DialogContainer';
// import ChromeExtensionBanner from '../../chrome-extension-banner/components/ChromeExtensionBanner.web';
// import OverlayContainer from '../../overlay/components/web/OverlayContainer';
//
// import { AbstractApp } from './AbstractApp';
//
// // Register middlewares and reducers.
// import '../middlewares';
// import '../reducers';
//
// export class App extends AbstractApp {
//
//     _createExtraElement() {
//         return (
//             <JitsiThemeProvider>
//                 <OverlayContainer />
//             </JitsiThemeProvider>
//         );
//     }
//
//
//     _createMainElement(component: React.ComponentType, props?: Object) {
//         return (
//             <JitsiThemeProvider>
//                 <GlobalStyles />
//                 <ChromeExtensionBanner />
//                 {super._createMainElement(component, props)}
//             </JitsiThemeProvider>
//         );
//     }
//
//
//     _renderDialogContainer() {
//         return (
//             <JitsiThemeProvider>
//                 <DialogContainer />
//             </JitsiThemeProvider>
//         );
//     }
//
//
//     render() {
//         return (
//             <Provider store={store}>
//                 <JitsiThemeProvider>
//                     <GlobalStyles />
//                     <ChromeExtensionBanner />
//                     <OverlayContainer />
//                     {this._createExtraElement()}
//                     {this._createMainElement(this.state.route.component, this.state.route.props)}
//                     {this._renderDialogContainer()}
//                 </JitsiThemeProvider>
//             </Provider>
//         );
//     }
// }
