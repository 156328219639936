// Wait to dynamically import './stream.js' first
import { sendMessage } from '../../chat/actions.any.ts'; // Adjust path as needed
import { onStart, onStop, testModuleFunctions } from './speech_recognition.js';

class SpeechRecognitionInterface {
    constructor(APP, audioStream) {
        this._dispatch = APP.store.dispatch;
        this.audioStream = audioStream;
        console.log("hansoo -> speech recognition interface constructor");  // Log the transcription to the console or update the UI
        if (!(audioStream instanceof MediaStream)) {
            console.error("hansoo Error: audioStream is not a valid MediaStream object.");
            return;
        }
        else
        {
            console.log("hansoo zz speech recognition init is meida hansoo async initilize speech recogntiion before");
            onStart(this.audioStream);
            window.addEventListener('speech_transcription', this.handleTranscription.bind(this));
        }
    }
    // Add a method for handling transcription events
    handleTranscription(event) {
        const { text } = event.detail;
        this._dispatch(sendMessage(text));
        console.log("hansoo -> Transcription:", text);  // Log the transcription to the console or update the UI
    }

    // Ensure cleanup when the instance is destroyed
    destructor() {
        onStop();
        window.removeEventListener('speech_transcription', this.handleTranscription);
    }
}

export { SpeechRecognitionInterface };
