import {
    getLocalJitsiVideoTrack,
    getLocalJitsiAudioTrack,
    getLocalTracks,
} from '../base/tracks/functions';

// handtrack features
import { HandTrack } from './HandTrack.js';
import { SnapShot } from './SnapShot.js';
import { VideoRecord } from './VideoRecord.js';
import { SpeechRecognitionInterface } from './speech_recognition/Speech_Recognition_Interface.js';
import { Startup_Face_Recognition } from './face_recognition/faceRecognitionScriptLoader.js';

// Startup_Face_Recognition();

import { CAMERA_FACING_MODE, MEDIA_TYPE, MediaType, VIDEO_TYPE } from '../base/media/constants';

if (CAMERA_FACING_MODE) {
    console.log('hansoo CAMERA_FACING_MODE file loaded');
} else {
    console.log('hansoo CAMERA_FACING_MODE not loaded');
}


import { useDispatch, useSelector } from 'react-redux';
// const localFlipX = useSelector((state: IReduxState) => state['features/base/settings'].localFlipX);
// const localFlipX = useSelector((state: IReduxState)  => state['features/base/settings'].localFlipX);

// const uiMouseOrHand = useSelector((state: IReduxState)  => state['features/base/settings'].uiMouseOrHand);
// const uiMouseOrHand = useSelector((state) => state['features/base/settings'].uiMouseOrHand);

class AnnotationDraw {
    constructor(APP)
    {
        this._store = APP.store;
        this._videoElement = document.createElement('video');
        this._videoElement.autoplay = true;
        this._outputCanvas = document.createElement('canvas');
        this._drawCanvas = document.createElement('canvas');
    }
    startEffect(_stream) // called externally
    {
        this._videoElement.srcObject = _stream;
        // Once video is loaded, start drawing
        this._videoElement.addEventListener('loadedmetadata', () => {
            // Set the canvas dimensions to match the video
            this._outputCanvas.width = this._videoElement.videoWidth;
            this._outputCanvas.height = this._videoElement.videoHeight;
            this._drawCanvas.width = this._videoElement.videoWidth;
            this._drawCanvas.height = this._videoElement.videoHeight;
            this._debug();
            this._drawcircle(0.5,0.5);
            this._postProcess();
        });

        return this._outputCanvas.captureStream(); // Return the modified stream
    }
    _debug()
    {
        const squareSize = 10;
        const centerX = (this._drawCanvas.width - squareSize) / 2;
        const centerY = (this._drawCanvas.height - squareSize) / 2;
        const _ctx = this._drawCanvas.getContext('2d');
        _ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
        _ctx.fillRect(centerX, centerY, squareSize, squareSize);
    }

    _drawcircle(x_pos, y_pos) {
        const radius = 40; // Radius of the circle
        const _ctx = this._drawCanvas.getContext('2d');

        // Calculate the circle's center position based on normalized values
        const centerX = x_pos * this._drawCanvas.width;
        const centerY = y_pos * this._drawCanvas.height;

        _ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)'; // Border color
        _ctx.lineWidth = 5; // Border thickness
        _ctx.beginPath();
        _ctx.arc(centerX, centerY, radius, 0, Math.PI * 2); // Draw the circle
        _ctx.stroke(); // Render the border
    }


    stopEffect() // called externally
    {

    }
    _drawDecodeOrientation(frame) {
        // Get the 2D rendering context from the canvas
        const _ctx = frame.getContext('2d');
        // Get the color of the top-left corner pixel
        const imageData = _ctx.getImageData(0, 0, 1, 1); // Single pixel at (0, 0)
        const [r, g, b] = imageData.data; // Red, Green, Blue channels
        // If the box is black, the pixel will have RGB values close to 0
        return r === 0 && g === 0 && b === 0; // Perfect black
    }
    _postProcess() {
        const drawFrame = () => {
            // Draw the current video frame onto the canvas
            this._drawVideoFrame();
            // Apply a simple effect (drawing a red square in the middle)
            this._overlayAnnotation();
            // Continue the animation loop
            // check if self participant

            ///////////////
            // const state = this._store.getState();
            // const pinnedParticipant = state['features/large-video']?.participantId;
            // if(this._participant_id_self === pinnedParticipant) //
            // {
            //     this._drawEncodeOrientation();
            // }
            // //////////////////
            requestAnimationFrame(drawFrame);
        };
        drawFrame(); // Start the drawing loop
    }
    _drawEncodeOrientation() {
        const _ctx = this._outputCanvas.getContext('2d');
        // Dimensions for the boxes
        const blackBoxWidth = 100;
        const blackBoxHeight = 50;
        const whiteBoxWidth = 100;
        const whiteBoxHeight = 50;

        // Overlay a black box on the top-left corner
        _ctx.fillStyle = 'black';
        _ctx.fillRect(0, 0, blackBoxWidth, blackBoxHeight);

        // Overlay a white box on the top-right corner
        const canvasWidth = this._outputCanvas.width;
        _ctx.fillStyle = 'white';
        _ctx.fillRect(canvasWidth - whiteBoxWidth, 0, whiteBoxWidth, whiteBoxHeight);
    }
    _drawVideoFrame() {
        // Draw the current video frame onto the canvas
        const _ctx = this._outputCanvas.getContext('2d');
        _ctx.drawImage(this._videoElement, 0, 0, this._outputCanvas.width, this._outputCanvas.height);
        // Call the function to draw overlays
    }
    _overlayAnnotation() {
        const _ctx = this._outputCanvas.getContext('2d');
        _ctx.drawImage(this._drawCanvas, 0, 0);
    }
    _Update_Draw(data)
    {
        if (data._mouseLeftButtonClick) {
            // console.log('mouse left down to draw');
            this._annotation_draw(data);
        } else {
        }
        if (data._mouseRightButtonClick) {
            const context = this._drawCanvas.getContext('2d');
            context.clearRect(0, 0, this._drawCanvas.width, this._drawCanvas.height);
        } else {
        }
    }
    _annotation_draw(data) {
        //
        // this._result_refVideoElement = document.getElementById('largeVideo');
        //
        // // Create a canvas element if not already created
        // this._resultoutputCanvas = document.createElement('canvas');
        //
        // // Set the canvas dimensions to match the video dimensions
        // this._resultoutputCanvas.width = this._result_refVideoElement.videoWidth;
        // this._resultoutputCanvas.height = this._result_refVideoElement.videoHeight;
        //
        // // Get the 2D rendering context
        // const _ctx = this._resultoutputCanvas.getContext('2d');
        //
        // // Draw the current video frame onto the canvas
        // _ctx.drawImage(this._result_refVideoElement, 0, 0, this._resultoutputCanvas.width, this._resultoutputCanvas.height);
        //
        // // Now you can use _ctx for further operations
        // // For example, get the top-left pixel color
        // const imageData = _ctx.getImageData(0, 0, 1, 1);
        // const [r, g, b, a] = imageData.data;
        //
        // console.log(`Top-left pixel color:
        // Red: ${r}, Green: ${g}, Blue: ${b}, Alpha: ${a}`);

        // Retrieve the RGBA values of the pixel
        /////////////////////////////////////

        //
        const canvasX = Math.floor(data._mouseX * this._drawCanvas.width);
        const canvasY = Math.floor(data._mouseY * this._drawCanvas.height);
        const context = this._drawCanvas.getContext('2d');

        // console.log('99 Annotation draw - call', canvasX, canvasY, this._drawCanvas.width, this._drawCanvas.height);

        if(!data._erase)
        {
            if (data._mouseXLast < 0 || data._mouseYLast < 0) {
                const imageData = context.getImageData(0, 0, this._drawCanvas.width, this._drawCanvas.height);
                const dataPixels = imageData.data;
                const squareSize = 2;
                for (let y = -Math.floor(squareSize / 2); y <= Math.floor(squareSize / 2); y++) {
                    for (let x = -Math.floor(squareSize / 2); x <= Math.floor(squareSize / 2); x++) {
                        const drawX = canvasX + x;
                        const drawY = canvasY + y;
                        if (drawX >= 0 && drawX < this._drawCanvas.width && drawY >= 0 && drawY < this._drawCanvas.height) {
                            const index = (drawY * this._drawCanvas.width + drawX) * 4;
                            dataPixels[index] = 255; // Red
                            dataPixels[index + 1] = 0; // Green
                            dataPixels[index + 2] = 0; // Blue
                            dataPixels[index + 3] = 255; // Alpha
                        }
                    }
                }
                context.putImageData(imageData, 0, 0);
            } else {
                const canvasXLast = Math.floor(data._mouseXLast * this._drawCanvas.width);
                const canvasYLast = Math.floor(data._mouseYLast * this._drawCanvas.height);
                context.beginPath();
                context.moveTo(canvasXLast, canvasYLast);
                context.lineTo(canvasX, canvasY);
                context.strokeStyle = 'red';
                context.lineWidth = 2;
                context.stroke();
            }
        }
        else // erase
        {
            context.globalCompositeOperation = 'destination-out';
            if (data._mouseXLast < 0 || data._mouseYLast < 0) {

            } else {
                const canvasXLast = Math.floor(data._mouseXLast * this._drawCanvas.width);
                const canvasYLast = Math.floor(data._mouseYLast * this._drawCanvas.height);
                context.beginPath();
                context.moveTo(canvasXLast, canvasYLast);
                context.lineTo(canvasX, canvasY);
                context.lineWidth = 30;
                context.stroke();
                console.log('callin erase annoation line');
            }
        }
        context.globalCompositeOperation = 'source-over';
    }
}

class Annotation {
    constructor(APP, participant_id_self, room, JitsiConferenceEvents)
    {
        //console.log("hansoo x Reducer for", 'UPDATE_UI_BUTTON_POSITION', "registered.");
        this._onMouseDownBound = this._onMouseDown.bind(this);
        this._onMouseMoveBound = this._onMouseMove.bind(this);
        this._onMouseUpBound = this._onMouseUp.bind(this);
        //
        this._APP = APP;
        this._store = APP.store;
        this._conference = APP.conference;
        this._participant_id_self = participant_id_self;
        this._AnnotationDrawEffect = new AnnotationDraw(APP);

        this._refVideoElement = document.getElementById('largeVideo');
        //this._reflocalVideoElement = document.getElementById('localVideoContainer');

        this._reflocalVideoElement = document.createElement('video');
        this._reflocalVideoElement.id = 'video'; // Set the ID here
        // listen to flip start
        // Fetch the initial state
        this._localFlipX = false;
        this._isSendFlip = false;
        // console.log("Initial localFlipX state:", this._localFlipX);
        this._hand_x_pos = 0.5;
        this._hand_y_pos = 0.5;
        // Subscribe to store updates
        this._unsubscribe = this._store.subscribe(() => {
            const newState = this._store.getState();

            this._isSendFlip  = newState['features/base/settings'].isSendFlip;
            // console.log('send flip update annotation.js->', this._isSendFlip );
            // this._localFlipX  = newState['features/base/settings'].localFlipX;
            // this._CameraFacingUser = newState['features/base/settings'].isCameraFacingUser;
            // this._setupAnnotationListener(this._CameraFacingUser);
        });
        // listen to flip end

        this._handTrack = null; // handtrack feature

        this._bridgeChannelReady = false; // for transmitting messages to others
        // State variables
        this._state = {
            _sender: this._participant_id_self,
            _receiver: this._participant_id_self,
            _mouseLeftButtonClick: false,
            _mouseRightButtonClick: false,
            _mouseX: 0, // normalized values
            _mouseY: 0, // normalized values
            _mouseXLast: -1,
            _mouseYLast: -1,
            _erase: false,
        };

        this._camera_facing_mode_initialized = false;
        // Check if track is already created before annotation constructor
        const track = getLocalJitsiVideoTrack(this._store.getState());
        if (track) {
            console.log("hansoo initialize annotation call 1");
            this._initialize_annotation(track);
        } else {
            // Listen for added tracks (e.g., user turns on webcam after joining the conference)
            room.on(JitsiConferenceEvents.TRACK_ADDED, track => {
                if (track.isLocal() && track.getType() === 'video') {
                    console.log("hansoo initialize annotation call 2 late");
                    this._initialize_annotation(track);
                }
            });
        }
        // Listen for removed tracks
        room.on(JitsiConferenceEvents.TRACK_REMOVED, track => {
            if (track.isLocal() && track.getType() === 'video') {
                // console.log('_uninitialize_stream annotation event call', track);
                this._uninitialize_annotation();
            }
        });
        // Conference events
        room.on(JitsiConferenceEvents.DATA_CHANNEL_OPENED, () => {
            console.log('hansoo Annotation - BridgeChannel is now ready.');
            this._bridgeChannelReady = true;

        });
        room.on(JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED, (participant, data) => {
            if (data?.type === 'annotation') {
                // console.log('7 Annotation - Received annotation from other participant.');
                if(data.data._receiver == this._participant_id_self)
                {
                    // flip
                    // data._mouseXLast = 1 - data._mouseXLast; // mouse woring, but not hand
                    // hand is flipped
                    // flip
                    this._AnnotationDrawEffect._Update_Draw(data.data);
                }
            }
        });
        // this._setupAnnotationListener(uiMouseOrHand); // default mouse annotation
        this._setupAnnotationListener(false); // default mouse annotation
        window.addEventListener('AnnotationMouseHandToggle', (event) => {
            console.log('hansoo receive set mouse hand toggle-->', event.detail.mousehandstate );
            this._setupAnnotationListener(event.detail.mousehandstate);
        });
        // this._setupAnnotationListener(false); // default mouse annotation
        // listen to voice command to annotate
        window.addEventListener('VoiceAnnotation', (event) => {
            if(this._hand_x_pos > 0 && this._hand_y_pos > 0)
            {
                this._AnnotationDrawEffect._drawcircle(this._hand_x_pos, this._hand_y_pos);
            }
            else
            {
                this._AnnotationDrawEffect._drawcircle(0.5, 0.5);
            }
        });
    }

    _getLocalAudioStream() {
        console.log("hansoo xaudio 0 Attempting to retrieve the local audio stream from the conference.");

        const state = this._APP.store.getState();
        const localAudio = getLocalJitsiAudioTrack(state);
        if (localAudio) {
            // Get the original media stream from the audio track
            const audioStream = localAudio.getOriginalStream();
            console.log("hansoo xaudio 1 Local audio stream obtained from conference:", audioStream);
            // Store or use the audioStream as needed
            this._localAudioStream = audioStream;
        } else {
            console.error("hansoo xaudio 2 No local audio track found in the conference.");
        }
    }

    _setupAnnotationListener(isMouse)
    {
        // if (this._camera_facing_mode_initialized)
        // {
        //     return;
        // }

        console.log('hansoo -> annotation.js receive setupannotation listner is mouse->',isMouse);
        if (isMouse) {
            // Add mouse annotation events


            window.addEventListener('mousedown', this._onMouseDownBound);
            window.addEventListener('mousemove', this._onMouseMoveBound);
            window.addEventListener('mouseup', this._onMouseUpBound);

            // Remove hand annotation events
            if (this._onIndexFingerTipDetected) {
                window.removeEventListener('indexFingerTipDetected', this._onIndexFingerTipDetected);
            }
        } else { // Using hand
            // Remove mouse annotation events
            if (this._onMouseDownBound) {
                window.removeEventListener('mousedown', this._onMouseDownBound);
                window.removeEventListener('mousemove', this._onMouseMoveBound);
                window.removeEventListener('mouseup', this._onMouseUpBound);
            }

            // Add hand annotation events
            this._onIndexFingerTipDetected = (event) => {
                const { activate, x, y } = event.detail;

                this._hand_x_pos = x;
                this._hand_y_pos = y;
                // console.log('hand update->', this._hand_x_pos, this._hand_y_pos);


                this._state._mouseLeftButtonClick = activate;
                if (activate) {
                    this._state._mouseX = x;
                    this._state._mouseY = y;
                    this._annotation_call();
                    this._state._mouseXLast = this._state._mouseX;
                    this._state._mouseYLast = this._state._mouseY;
                } else {
                    this._state._mouseXLast = this._state._mouseYLast = -1;
                    this._annotation_call();
                }
            };

            window.addEventListener('indexFingerTipDetected', this._onIndexFingerTipDetected);
        }
        // this._camera_facing_mode_initialized = true;

        // window.addEventListener('mousedown', this._onMouseDownBound);
        // window.addEventListener('mousemove', this._onMouseMoveBound);
        // window.addEventListener('mouseup', this._onMouseUpBound);
        // this._onIndexFingerTipDetected = (event) => {
        //     const { activate, x, y } = event.detail;
        //
        //     this._state._mouseLeftButtonClick = activate;
        //     if (activate) {
        //         this._state._mouseX = x;
        //         this._state._mouseY = y;
        //         this._annotation_call();
        //         this._state._mouseXLast = this._state._mouseX;
        //         this._state._mouseYLast = this._state._mouseY;
        //     } else {
        //         this._state._mouseXLast = this._state._mouseYLast = -1;
        //         this._annotation_call();
        //     }
        // };
        // window.addEventListener('indexFingerTipDetected', this._onIndexFingerTipDetected);
    }

    _initialize_annotation(track) {

        console.log('Annotation - initialized');
        const modifiedStream = this._AnnotationDrawEffect.startEffect(track.getOriginalStream());
        const modifiedTrack = modifiedStream.getVideoTracks()[0];
        track._startStreamEffect(this._AnnotationDrawEffect);
        // set up drawing area bounds only
        this._refVideoElement = document.getElementById('largeVideo');
        // set up mouse events

        // initialize handtrack feature
        // if(this._CameraFacingUser === false)
        // {
            console.log("hansoo Before HandTrack initialization");
            this._reflocalVideoElement.srcObject = track.getOriginalStream();
            this._handTrack = new HandTrack(this._reflocalVideoElement, this._store.dispatch);
            console.log("hansoo After HandTrack initialization");
        // }
        //
        console.log("hansoo Before SnapShot initialization");
        this._SnapShot = new SnapShot(this._refVideoElement);
        console.log("hansoo After SnapShot initialization");

        console.log("hansoo Before VideoRecord initialization");
        // this._VideoRecord = new VideoRecord(this._refVideoElement);
        this._VideoRecord = new VideoRecord(this._store);
        window.videoRecordInstance = this._VideoRecord;
        console.log("hansoo After VideoRecord initialization");

        //
        // get local audio stream
        console.log("hansoo xaudio hansoo before speech recognition initialization");
        this._getLocalAudioStream();
        // Get setup speech recognition
        console.log("hansoo before speech recognition initialization");
        this._SpeechRecognition = new SpeechRecognitionInterface(this._APP, this._localAudioStream);
        console.log("hansoo After speech recognition initialization");
        //
        //
        // listen to trigger annoation ui button clear
        window.addEventListener('AnnotationClearTrigger', (event) => {
            // const state = event.detail.state;  // Retrieve the state from the event's detail
            // this._trigger_annotation_clear(state);  // Pass state to the handler
            this._trigger_annotation_clear();  // Pass state to the handler
        });

        window.addEventListener('AnnotationDrawEraseToggle', (event) => {
            this._state._erase = !this._state._erase;
        });


        // have face recognition scripts update states and data
        window.faceRecognitionStore = this._store;
        Startup_Face_Recognition();
/*
        // Add an event listener to clean up resources when the video element is no longer available
        this._refVideoElement.addEventListener('ended', () => {
        console.log("hansoo Video has ended, destroying hand landmarker tracker.");
        HandTracker.destroy();
        });
        //
*/

    }
    _uninitialize_annotation()
    {

    }
    _trigger_annotation_clear() // from UI button input
    {
        console.log('hansoo annotation _trigger_annotation_clear called');
        this._state._mouseRightButtonClick = true;
        // this._annotation_call();
        this._annotation_call_decouple();
        this._state._mouseRightButtonClick = false;
        this._state._mouseXLast = this._state._mouseYLast = -1;
    }
    _onMouseDown(event) {
        if (event.button === 0) {
            //console.log('annotation mouse button left down');
            this._state._mouseLeftButtonClick = true;
            this._annotation_call();
        } else if (event.button === 2) {
            //console.log('annotation mouse button right down');
            this._state._mouseRightButtonClick = true;
            this._annotation_call();
            this._state._mouseRightButtonClick = false;
            this._state._mouseXLast = this._state._mouseYLast = -1;
        }
    }
    _onMouseUp(event) {
        if (event.button === 0) {
            //console.log('annotation mouse button left up');
            this._state._mouseLeftButtonClick = false;
            this._annotation_call();
            this._state._mouseXLast = this._state._mouseYLast = -1;
        } /*else if (event.button === 2) {
            console.log('annotation mouse button right up');
            this._state._mouseRightButtonClick = false;
            this._state._mouseXLast = this._state._mouseYLast = -1;
            this._annotation_call();
        }*/
    }
    _onMouseMove(event) {
        // const state = APP.store.getState();
        // const { localFlipX: currentFlipX } = state['features/base/settings'];


        if (!this._refVideoElement || (!this._state._mouseLeftButtonClick && !this._state._mouseRightButtonClick)) return;
        const rect = this._refVideoElement.getBoundingClientRect();
        // this._state._mouseX = this._localFlipX ? 1 - (event.clientX - rect.left) / rect.width : (event.clientX - rect.left) / rect.width;
        this._state._mouseX =   1 - (event.clientX - rect.left) / rect.width;
        this._state._mouseY = (event.clientY - rect.top) / rect.height;
        this._annotation_call();

        this._state._mouseXLast = this._state._mouseX;
        this._state._mouseYLast = this._state._mouseY;
    }
    _annotation_call_decouple()
    {
        // const state = this._store.getState();
        // temp
        const pinnedParticipant = this._state._receiver; //state['features/large-video']?.participantId;
        if (pinnedParticipant) {
            this._state._receiver = pinnedParticipant;
            if (this._state._sender === this._state._receiver) {
                this._AnnotationDrawEffect._Update_Draw(this._state);
            } else {
                if (this._bridgeChannelReady) {
                    if(this._isSendFlip)
                    {
                        this._state._mouseX = 1 - this._state._mouseX;
                        console.log('+ annotation send to external participant');
                    }
                    else
                    {
                        console.log('- annotation send to external participant');
                    }
                    const data = { type: 'annotation', data: this._state };
                    this._conference.sendEndpointMessage(this._state._receiver, data);
                } else {
                    console.log('12 Annotation - BridgeChannel is not ready.');
                }
            }

        } else {
            console.log('13 Annotation - Pinned participant is null.');
        }
    }
    _annotation_call()
    {
        const state = this._store.getState();
        const pinnedParticipant = state['features/large-video']?.participantId;
        if (pinnedParticipant) {
            this._state._receiver = pinnedParticipant;
            if (this._state._sender === this._state._receiver) {
                    if(this._isSendFlip) // front camera flip drawing for magic leap 2 when using controller
                    {
                        this._state._mouseX = 1 - this._state._mouseX;
                    }
                this._AnnotationDrawEffect._Update_Draw(this._state);
            } else {
                if (this._bridgeChannelReady) {
                    if(this._isSendFlip)
                    {
                        this._state._mouseX = 1 - this._state._mouseX;
                        // console.log('+ annotation send to external participant');
                    }
                    else
                    {
                        // console.log('- annotation send to external participant');
                    }
                    // console.log('annotation send to external participant');
                    const data = { type: 'annotation', data: this._state };
                    this._conference.sendEndpointMessage(this._state._receiver, data);
                } else {
                    //console.log('12 Annotation - BridgeChannel is not ready.');
                }
            }

        } else {
            //console.log('13 Annotation - Pinned participant is null.');
        }
    }
}

export { Annotation };

        // if(isMouse)
        // {
        //     window.addEventListener('mousedown', (event) => this._onMouseDown(event));
        //     window.addEventListener('mousemove', (event) => this._onMouseMove(event));
        //     window.addEventListener('mouseup', (event) => this._onMouseUp(event));
        //
        //     // add mouse annotation events
        //     // remove hand annotation events
        //     window.removeEventListener('indexFingerTipDetected', this._onIndexFingerTipDetected);
        // }
        // else // using hand
        // {
        //     // remove mouse annotation events
        //     window.removeEventListener('mousedown', (event) => this._onMouseDown(event));
        //     window.removeEventListener('mousemove', (event) => this._onMouseMove(event));
        //     window.removeEventListener('mouseup', (event) => this._onMouseUp(event));
        //     // add hand annotation events
        //
        //     window.addEventListener('indexFingerTipDetected', (event) => {
        //     const {activate, x, y } = event.detail;
        //         //console.log(`hansoo hand - ${activate} x: ${x}, y: ${y}`);
        //         // You can add any custom logic here to use the detected finger tip coordinates
        //         // For example, drawing a marker on a UI, controlling something, etc.
        //         this._state._mouseLeftButtonClick = activate;
        //         if(activate)
        //         {
        //             // console.log(`hansoo hand on`);
        //             // this._state._mouseX = 1-x; // flip
        //             this._state._mouseX = x;
        //             this._state._mouseY = y;
        //             this._annotation_call();
        //             this._state._mouseXLast = this._state._mouseX;
        //             this._state._mouseYLast = this._state._mouseY;
        //         }
        //         else
        //         {
        //             // console.log(`hansoo hand off`);
        //             this._state._mouseXLast = this._state._mouseYLast = -1;
        //             this._annotation_call();
        //         }
        //         /*
        //         */
        //     });
        // }
