// import { toggleVideoRecording, triggerImageSnapshot, toggleAnnotationDrawErase, triggerAnnotationClear, updatelastHoveredButtonIndex, updateUIHandMenuPosition, updateUIButtonPosition, toggleMouseHandAnnotation, triggerFlipWindowUI} from  '../../../base/settings/actions';
// import toggleVideoRecording from  '../../base/settings/actions';
import { toggleVideoRecording } from '../../base/settings/actions';
import { useDispatch/*, useSelector */} from 'react-redux';
// Priority configuration
export const priorities = [
    { words: ["one two", "1 2", "12"], action: "draw annotation" },
    { words: ["one three", "1 3", "13", "one tree", "1 tree"], action: "clear annotation" },
    { words: ["one four", "1 4", "14", "one full", "one fall", "one for"], action: "image snapshot" },
    { words: ["one five", "1 5", "15"], action: "video record toggle" },
    { words: ["one one", "1 1", "11"], action: "nothing" },
];

// Function to normalize input
export function normalizeInput(input) {
    return input
        .toLowerCase() // Convert to lowercase
        .replace(/[,]/g, " ") // Replace commas with spaces
        .replace(/\s+/g, " ") // Replace multiple spaces with a single space
        .trim(); // Trim leading/trailing spaces
}

// Function to detect activation
export function detectActivation(input) {
    const normalizedInput = normalizeInput(input);

    for (const priority of priorities) {
        for (const word of priority.words) {
            const normalizedWord = normalizeInput(word); // Ensure keywords are also normalized
            if (normalizedInput.includes(normalizedWord)) {
                return priority.action;
            }
        }
    }

    return "No action detected";
}

// Example usage - uncomment this to run example
// const sentences = [
//     "one two",        // Should activate "draw annotation"
//     "1,2",            // Should activate "draw annotation"
//     "1  3",           // Should activate "clear annotation"
//     "14",             // Should activate "image snapshot"
//     "start recording with 1 , 5", // Should activate "video record toggle"
//     "one,one",        // Should activate "nothing"
//     "random text"     // Should activate "No action detected"
// ];
//
// console.log("sample voice command start.");
//
// sentences.forEach(sentence => {
//     interpretVoiceCommand(sentence);
// });
//
// console.log("sample voice command end.");
//

// Function to interpret a sentence and determine if follow-up action is needed
export default function interpretVoiceCommand(sentence) {
    const action = detectActivation(sentence);
    if (action !== "No action detected") {
        console.log(`Follow-up action required: ${action}`);
        if (action === "draw annotation")
        {
            console.log(" voice command dispatch event VoiceAnnotation ");
            const event_annotation_voice = new CustomEvent('VoiceAnnotation', {
            });
            window.dispatchEvent(event_annotation_voice);
        }
        if (action === "clear annotation")
        {
            console.log(" voice command dispatch event AnnotationClearTrigger");

            const event_annotation_clear = new CustomEvent('AnnotationClearTrigger', {
                // detail: {
                //     state: state  // Pass the state in the 'detail'
                // }
            });
            window.dispatchEvent(event_annotation_clear);
        }
        if (action === "image snapshot")
        {
            console.log(" voice command dispatch event imageSnapshotActivated");
            const snapshotEvent = new CustomEvent('imageSnapshotActivated', {
                detail: { message: 'Image Snapshot triggered!' },
            });
            window.dispatchEvent(snapshotEvent); // Trigger snapshot event
        }
        if (action === "video record toggle")
        {
            // console.log(" voice command dispatch event video record toggle");
            // const dispatch = useDispatch();
            // dispatch(toggleVideoRecording(false)); // always send false at first
            //
            // // newState.isRecording = false;
            // const videorecordingevent = new CustomEvent('videoRecordingToggled', {
            //     // detail: leftHandData
            // });
            // window.dispatchEvent(videorecordingevent);
        }
        return action;
    } else {
        console.log("No follow-up action required.");
        return null;
    }
}
