import { toggleVideoRecording } from '../base/settings/actions';


class VideoRecord {
    constructor(store) {
        this.mediaRecorder = null;
        this.recordedChunks = [];
        this.currentlyRecording = false;
        this.stream = null;
        this.store = store;

        // Add event listener for 'r' key to toggle recording
        document.addEventListener('keydown', (event) => {
            if (event.key === 'r') {
                this.toggleRecording();
            }
        });

        // Listen to custom events for toggling recording
        window.addEventListener('videoRecordingToggled', (event) => {
            this.toggleRecording();
        });


        // // Add event listener for middle mouse button click
        // window.addEventListener('mousedown', (event) => {
        //     if (event.button === 1) { // 1 corresponds to middle mouse button
        //         event.preventDefault(); // Prevent default middle mouse behavior
        //         this.captureVideoSnapshot(); // Capture video snapshot on middle mouse click
        //     }
        // });
        // // listen to event jitsi-meet/react/features/base/settings/reducer.ts
        // window.addEventListener('imageSnapshotActivated', (event) => {
        //         this.captureVideoSnapshot(); // Capture video snapshot on middle mouse click
        // });
        //
        // // debug
        // this.captureVideoSnapshot();
    }

    destroy() {
        if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
        }
    }

    // Utility: Format date and time as a string
    getFormattedTimestamp() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    }

    // Method to toggle recording on and off
    async toggleRecording() {
        if (!this.currentlyRecording) {
            const startedSuccessfully = await this.startRecording();
            if (startedSuccessfully) {
                this.store.dispatch(toggleVideoRecording(startedSuccessfully));
            }
        } else {
            this.stopRecording();
        }
    }

    // Method to start recording
    async startRecording() {
        console.log('Starting recording...');
        try {
            // Capture screen video
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: { mediaSource: 'screen' },
                audio: true, // Attempt to capture system audio
            });

            // Capture microphone audio
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // Combine screen video and microphone audio into a single stream
            const combinedStream = new MediaStream([
                ...screenStream.getVideoTracks(),
                ...audioStream.getAudioTracks(),
            ]);

            // Clear the buffer before starting a new recording
            this.recordedChunks = [];

            // Create a MediaRecorder instance to record the combined stream
            this.mediaRecorder = new MediaRecorder(combinedStream);
            this.mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    this.recordedChunks.push(event.data);
                }
            };

            this.mediaRecorder.onstop = this.handleRecordingStop.bind(this);

            // Start recording
            this.mediaRecorder.start();
        } catch (error) {
            console.error('Error starting screen recording:', error);
            return false;
        }

        this.currentlyRecording = true;
        return true;
    }

    // Method to stop recording
    stopRecording() {
        if (this.mediaRecorder && this.currentlyRecording) {
            this.mediaRecorder.stop();
            this.currentlyRecording = false;

            // Stop all tracks to release the stream
            if (this.stream) {
                this.stream.getTracks().forEach((track) => track.stop());
            }

            console.log('Screen recording stopped.');
            return true;
        } else {
            return false;
        }
    }

    // Method to handle when recording stops
    handleRecordingStop() {
        const recordedBlob = new Blob(this.recordedChunks, { type: 'video/webm' });
        const url = URL.createObjectURL(recordedBlob);

        // Generate a timestamped filename
        const timestamp = this.getFormattedTimestamp();

        // Trigger download of the recorded video
        this.downloadRecording(url, `screen_recording_${timestamp}.webm`);

        // Clear the buffer after saving the recording
        this.recordedChunks = [];
    }

    // Method to trigger a download of the recorded video
    downloadRecording(blobUrl, filename) {
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    async captureVideoSnapshot() {
        try {
            // Request a temporary stream for capturing the screen
            const tempStream = await navigator.mediaDevices.getDisplayMedia({ video: true });

            // Create a video element to play the temporary stream
            const videoElement = document.createElement('video');
            videoElement.srcObject = tempStream;

            // Ensure the video metadata is loaded before processing
            await new Promise((resolve, reject) => {
                videoElement.onloadedmetadata = () => {
                    resolve();
                };
                videoElement.onerror = (error) => {
                    reject(new Error('Failed to load video metadata.'));
                };
            });

            // Play the video stream
            await videoElement.play();

            // Create an offscreen canvas
            const snapshotCanvas = document.createElement('canvas');
            snapshotCanvas.width = videoElement.videoWidth;
            snapshotCanvas.height = videoElement.videoHeight;

            const context = snapshotCanvas.getContext('2d');
            if (!context) {
                throw new Error('Failed to get canvas context.');
            }

            // Draw the current frame to the canvas
            context.drawImage(videoElement, 0, 0, snapshotCanvas.width, snapshotCanvas.height);

            // Extract the image as a data URL
            const imageDataUrl = snapshotCanvas.toDataURL('image/png');
            console.log('Snapshot captured:', imageDataUrl);

            // Trigger a download of the snapshot as .png
            this.downloadFile(imageDataUrl, `from_videorecord_debugnew_hansoo_debug_snapshot_${this.getFormattedTimestamp()}.png`);

            // Trigger a download of the snapshot data URL as .txt for verification
            const textDataUrl = `data:text/plain;charset=utf-8,${encodeURIComponent(imageDataUrl)}`;
            this.downloadFile(textDataUrl, `from_videorecord_debugnew_hansoo_debug_snapshot_${this.getFormattedTimestamp()}.txt`);

            // Stop the stream after capturing the snapshot
            tempStream.getTracks().forEach(track => track.stop());
        } catch (error) {
            console.error('Error capturing snapshot:', error);
        }
    }

    // async captureVideoSnapshot() {
    //     try {
    //         const tempStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
    //         const videoElement = document.createElement('video');
    //         videoElement.srcObject = tempStream;
    //
    //         await new Promise((resolve) => {
    //             videoElement.onloadedmetadata = resolve;
    //         });
    //
    //         await videoElement.play();
    //         const snapshotCanvas = document.createElement('canvas');
    //         snapshotCanvas.width = videoElement.videoWidth;
    //         snapshotCanvas.height = videoElement.videoHeight;
    //
    //         const context = snapshotCanvas.getContext('2d');
    //         context.drawImage(videoElement, 0, 0, snapshotCanvas.width, snapshotCanvas.height);
    //
    //         const imageDataUrl = snapshotCanvas.toDataURL('image/png');
    //         console.log('Snapshot captured:', imageDataUrl);
    //
    //         this.displaySaveDialog(imageDataUrl, `hansoo_debug_image_snapshot_${this.getFormattedTimestamp()}.png`);
    //
    //                     // Trigger a download of the snapshot data URL as .txt for verification
    //         const textDataUrl = `data:text/plain;charset=utf-8,${encodeURIComponent(imageDataUrl)}`;
    //         this.downloadFile(textDataUrl, `hansoo_debug_image_snapshot_${this.getFormattedTimestamp()}.txt`);
    //
    //         tempStream.getTracks().forEach((track) => track.stop());
    //     } catch (error) {
    //         console.error('Error capturing snapshot:', error);
    //     }
    // }


    // Helper method to trigger file downloads
    downloadFile(dataUrl, filename) {
        const anchor = document.createElement('a');
        anchor.href = dataUrl;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }



    downloadSnapshot(dataUrl, filename) {
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

}

export { VideoRecord };
