class SnapShot {
    constructor(videoElement) {
        // return;
        this.videoElement = videoElement;

        // Add event listener for middle mouse button click
        videoElement.addEventListener('mousedown', (event) => {
            if (event.button === 1) { // 1 corresponds to middle mouse button
                event.preventDefault(); // Prevent default middle mouse behavior
                // this.captureScreenSnapshot();
                this.captureVideoSnapshot(); // Capture video snapshot on middle mouse click
            }
        });
        // listen to event jitsi-meet/react/features/base/settings/reducer.ts
        window.addEventListener('imageSnapshotActivated', (event) => {
                this.captureVideoSnapshot(); // Capture video snapshot on middle mouse click
                // this.captureScreenSnapshot();
        });
    }
    // Utility: Format date and time as a string
    getFormattedTimestamp() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    }
    // Method to capture snapshot from the video element
    captureVideoSnapshot() {
        const videoElement = this.videoElement;

        // Create an offscreen canvas for the snapshot
        const snapshotCanvas = document.createElement('canvas');
        const context = snapshotCanvas.getContext('2d');

        // Set canvas dimensions to match the video
        snapshotCanvas.width = videoElement.videoWidth;
        snapshotCanvas.height = videoElement.videoHeight;

        // Draw the current video frame to the canvas
        context.drawImage(videoElement, 0, 0, snapshotCanvas.width, snapshotCanvas.height);

        // Convert the canvas content to a data URL
        const imageDataUrl = snapshotCanvas.toDataURL('image/png');
        console.log('hansoo Snapshot captured:', imageDataUrl);

        // Optionally, trigger a download of the snapshot
        const timestamp = this.getFormattedTimestamp();
        this.downloadImage(imageDataUrl, `origin_default_from_snapshot_debugnew_hansoo_image_video_snapshot_${timestamp}.png`);


        // const textDataUrl = `data:text/plain;charset=utf-8,${encodeURIComponent(imageDataUrl)}`;
        // this.downloadImage(textDataUrl, `origin_default_from_snapshot_debugnew_hansoo_image_video_snapshot_${timestamp}.txt`);
    }

    // Method to capture screen snapshot
    captureScreenSnapshot() {
        // Request permission to capture the screen
        navigator.mediaDevices.getDisplayMedia({ video: true })
            .then((stream) => {
                const screenVideoElement = document.createElement('video');
                screenVideoElement.srcObject = stream;
                screenVideoElement.play();

                // When the screen stream is playing, draw it to the canvas
                screenVideoElement.onloadedmetadata = () => {
                    const snapshotCanvas = document.createElement('canvas');
                    snapshotCanvas.width = screenVideoElement.videoWidth;
                    snapshotCanvas.height = screenVideoElement.videoHeight;

                    const context = snapshotCanvas.getContext('2d');
                    context.drawImage(screenVideoElement, 0, 0, snapshotCanvas.width, snapshotCanvas.height);

                    // Convert the canvas content to a data URL
                    const imageDataUrl = snapshotCanvas.toDataURL('image/png');
                    console.log('hansoo Screen snapshot captured:', imageDataUrl);

                    // Optionally, trigger a download of the snapshot
                    const timestamp = this.getFormattedTimestamp();
                    this.downloadImage(imageDataUrl, `last_final_from_snapshot_debugnew_screen_snapshot_${timestamp}.png`);

                    // Stop the screen stream after capturing
                    stream.getTracks().forEach(track => track.stop());
                };
            })
            .catch((err) => {
                console.error('hansoo Error capturing screen:', err);
            });
    }

    // Method to trigger a download of the snapshot
    downloadImage(dataUrl, filename) {
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}

export { SnapShot };
